@use '@angular/material' as mat;
// theme.scss

$my-app: (
  10:  #e8fafb2e,
  50:  #32c5d22e,
  100: #32c5d23d,
  200: orange,
  300: #32c5d25c,
  400: #8cdce4,
  500: #32c5d2c2,
  600: #32c5d2,
  700: #1f858e,
  800: #18666d,
  900: #337ab7,
  A100: #ffffff,
  A200: #def6f8,
  A400: #a1f3fb,
  A700: #7aecf7,
  contrast: (
    50 : #32c5d25c,
    100 : #32c5d2c2,
    200 : white,
    300 : #1f858e,
    400 : #18666d,
    500 : #337ab7,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #32c5d2,
    A200 : #1f858e,
    A400 : #18666d,
    A700 : #337ab7,
  ));

$my-app-primary: mat.define-palette($my-app, 600);
$my-app-accent : mat.define-palette($my-app, 900);
$my-app-warn : mat.define-palette($my-app, 200);
$my-app-sidebar : mat.define-palette($my-app, 10);
$my-app-background : mat.define-palette($my-app, 50);
$my-app-colored-card : mat.define-palette($my-app, 100);
$my-app-light : mat.define-palette($my-app, 200);


$comv-typography-config: mat.define-typography-config(

  $body-1: mat.define-typography-level(14px, 21px, 400) 
  // ...
);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent,
    warn: $my-app-warn
  ),
  typography: $comv-typography-config,
  density: 0,
));

$comv-dark-blue: #26344B;
$comv-middle-blue: #337ab7;
$comv-primary-blue: #32c5d2;
$comv-light-blue1: #EDF9FD;
$comv-light-blue2: #D7F3FB;
$comv-red: #ed6b75;
$comv-light-gray: #f1f1f1;
$comv-tab1: #0175c0;
$comv-tab2: #009BC6;
$comv-ultralight-gray: rgba(242, 242, 242, 0.15); 
$comv-table-header-border: #a8b7c1;


.mat-mdc-cell {
  color:dimgray !important;
}
.main-col {
  color: black !important;
  font-weight: bolder;
}

.mat-mdc-paginator, .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator::ng-deep {
  .mdc-text-field::ng-deep {
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__leading
       {
          border: none !important;
      }
  }
}

$comv-blue-dark :#04488f;
$comv-blue-medium :#0175c0;
$comv-blue-light :#00b0e1;
$comv-gray-medium :#8f8f8f;
$comv-yellow-light :#ecb842;
$comv-yellow-dark :#e6a540;
$comv-pink-light :#aa488d;
$comv-pink-dark :#7d478e;
$comv-green-light :#8db854;
$comv-green-dark :#54763f;


//$comv-dark-blue: #26344B;
// $comv-dark-blue : $comv-blue-dark;

//$comv-middle-blue: #337ab7;
$comv-middle-blue: $comv-blue-medium;
//$comv-primary-blue: #28b5b9;  
$comv-primary-blue: $comv-blue-light;

$comv-primary-blue-hover: #32c5d2;  

//$comv-accent-blue: #0065AF;
$comv-accent-blue: $comv-blue-medium;
$comv-accent-blue-hover: #0075C9;
$comv-light-blue1: #EDF9FD;
$comv-light-blue2: #D7F3FB;
$comv-light-blue3: #009BC6; 
$comv-light-blue3-hover: #00b3e3;

$comv-red: #ed6b75;
$comv-light-gray: #f1f1f1;
$comv-tab-icon:$comv-middle-blue;
$comv-tab-icon-hover:$comv-primary-blue;

$comv-grid-highlight: #f6f6f6;
$comv-grid-hover: #D7F3FB;

$comv-chips-color: $comv-light-gray;

$comv-admo-matched: greenyellow;
$comv-admo-disabled: lightgray;
$comv-admo-validated: green;
$comv-admo-published: $comv-primary-blue;
$comv-admo-split: #7e478e87;
$comv-admo-merge: #ffa500;