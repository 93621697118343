@use "@angular/material" as mat;
@import "./theme.scss";
@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100%3B300%3B400%3B500%3B700&display=swap";

@include mat.core();
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
* {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.hidden {
  display: none;
}

.hand {
  cursor: pointer;
}

.mat-radio-label {
  margin-right: 10px !important;
}

.pull-right {
  float: right;
}

// Tables
.std-table-container {
  position: relative;
  min-height: 200px;
  flex-direction: column;
}

.std-table-container > div {
  /* these are the flex items */
  flex: 1;
}

.std-table-table-container {
  position: relative;
  //max-height: 540px;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.std-table-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.std-table-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/* Column Widths */
.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

td.mat-mdc-cell.action-col {
  width: 40px;
  padding-right: 0px;
  text-align: center;
}

mat-spinner::ng-deep {
  &.bt-spinner {
    display: inline;
    padding-right: 30px;
  }
}

.hand {
  cursor: pointer;
}

.red-text {
  color: $comv-red;
}

.mat-mdc-form-field-error {
  color: $comv-red !important;
}

// hide arrows from input number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  margin: 0;
}

.mat-expansion-panel {
  margin-bottom: 1px !important;
  margin-top: 0px !important;
}

.mat-tooltip-info {
  .mdc-tooltip__surface {
    white-space: pre-line !important;
    background: #1f2b3de3;
    color: white !important;
    margin-right: 5px !important;
    font-size: 12px;
  }
}

.mat-mdc-cell.logo {
  span {
    img {
      width: 100px;
      border: 1px solid lightgray;
      display: block;
    }
  }
}

.mat-mdc-cell.logo70 {
  span {
    img {
      width: 70px;
      max-height: 70px;
      border: none;
      display: block;
      padding: 4px;
    }
  }
}

.mat-mdc-mini-fab .mat-button-wrapper {
  line-height: 0px !important;
}

.tab-error-icon {
  color: $comv-red;
  margin-top: -10px;
}

tr.mat-mdc-header-row {
  background: white !important;
}

tr.mat-mdc-row:nth-child(2n + 1):not(.expand-row) {
  background: $comv-grid-highlight;
}

tr.example-element-row.expand-row:nth-child(4n + 1) {
  background: $comv-grid-highlight;
}

tr.mat-mdc-row:hover:not(.mat-mdc-header-row) {
  background: $comv-grid-hover !important;
}

.actions-col {
  & button,
  & button:hover {
    background: transparent;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: $comv-chips-color;
  color: rgba(0, 0, 0, 0.87);
}

.badge {
  font-size: 12px !important;
  font-weight: bold;
  height: 18px;
  line-height: 18px;
  //color: #fff;
  padding: 3px 6px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  text-shadow: none !important;
  text-align: center;
  width: 80px;
  white-space: nowrap;

  &._0,
  &._null {
    border: 1.5px solid #77c5da;
    color: #77c5da !important;
    &.forced {
      background-color: #77c5da !important;
    }
  }

  &._25 {
    border: 1.5px solid #0175c0;
    color: #0175c0 !important;
    &.forced {
      background-color: #0175c0 !important;
    }
  }

  &._50 {
    border: 1.5px solid #e6a540;
    color: #e6a540 !important;
    &.forced {
      background-color: #e6a540 !important;
    }
  }

  &._75 {
    border: 1.5px solid #aa488d;
    color: #aa488d !important;
    &.forced {
      background-color: #aa488d !important;
    }
  }

  &._100 {
    border: 1.5px solid #b057a7;
    color: #b057a7 !important;
    &.forced {
      background-color: #b057a7 !important;
    }
  }

  &._inreview,
  &._125 {
    border: 1.5px solid #8db854;
    color: #8db854 !important;
  }

  &.forced {
    color: white !important;
  }

  &._pending,&._new {
    border: 1.5px solid #8db854;
    color: #8db854 !important;
  }

  &._in_progress {
    border: 1.5px solid #e6a540;
    color: #e6a540 !important;
  }

  &._finalized {
    border: 1.5px solid #77c5da;
    color: #77c5da !important;
  }

  &._published{
    border: 1.5px solid #0175c0;
    color: #0175c0 !important;
  }

  &._headcount{
    border: 1.5px solid #b057a7;
    color: #b057a7 !important;
  }
}

.cardinfo-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.mat-mdc-menu-panel {
  .unpublish-icon {
    mat-icon {
      transform: scaleY(-1);
    }
  }
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-icon-color: white !important;
  --mat-mdc-fab-color: white !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay,
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
  background-color: transparent !important;
  padding: 0px;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: white;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.mat-mdc-header-cell {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 14px;
}

.mat-mdc-card {
  padding: 16px;
}

.mdc-tab__text-label {
  letter-spacing: 0;
}

.mdc-dialog__actions {
  padding: 8px 24px 15px 24px !important;
}

.mdc-button {
  letter-spacing: 0px;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-item {
    mat-icon {
      color: rgba(0, 0, 0, 0.54) !important;
    }
    .mdc-list-item__primary-text {
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: 14px;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.bt_spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.mat-mdc-dialog-title {
  margin: 0 !important;
  margin-bottom: 20px !important;
  padding-top: 10px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353);
  // background-color: #aa488d !important;
  // background: linear-gradient(45deg, #04488f, transparent);
  background-color: #04488f !important;
  color: white !important;
  display: flex !important;
  align-items: center;
  mat-icon {
    margin-right: 10px;
    color: white;
  }
}

.history-popup {
  .mat-mdc-dialog-title {
    margin: 0 !important;
    margin-bottom: 20px !important;
    padding-top: 10px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353);
    background: none;
    background-color: rgba(0, 0, 0, 0.0705882353) !important;

    color: #666666 !important;
    display: flex !important;
    align-items: center;
    mat-icon {
      margin-right: 10px;
      color: white;
    }
  }
}

.status-chip {
  color: white !important;
  padding: 3px 7px;
  border-radius: 5px;
  font-size: 12px;
  width: fit-content;

  &.align-right {
    margin-left: 40px;
  }

  &.added,
  &.accepted {
    background-color: #8db854;
  }

  &.removed,
  &.rejected {
    background-color: #ed6b75;
  }

  &.updated {
    background-color: #ecb842;
  }
}

.status {
  font-size: 11px !important;
  font-weight: 300;
  height: 18px;
  line-height: 17px;
  color: #fff;
  padding: 3px 6px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  width: 80px;

  &.validated {
    background-color: #36c6d3;
  }

  &.pending {
    background-color: #f1970f;
  }
  &.not_confirmed {
    background-color: #f1c40f;
  }
  &.refused {
    background-color: #ed6b75;
  }
}

// RADAR
.radar_text_purple {
  color: #a64c8cff !important;
}
.radar_text_darkpurple {
  color: #5f488d !important;
}
.radar_text_blue {
  color: #1a76bdff !important;
}
.radar_text_darkblue {
  color: #04488f !important;
}
.radar_text_red {
  color: #bf0000ff !important;
}
.radar_text_dark {
  color: rgb(55, 55, 55) !important;
}

.radar-grid {
  .calendar {
    .row-cell {
      &.header-cell,
      &.footer-cell {
        background-color: #1a76bd !important;
      }
      &.even {
        background-color: white !important;
      }

      min-height: unset !important;
      p {
        margin: 5px 0px !important;
      }

      h1 {
        font-size: 16px !important;
      }
      h2 {
        font-size: 14px !important;
      }
    }
  }
}
.grid.radar-grid {
  .row {
    &.header-row,
    &.footer-row {
      .row-cell {
        background-color: #1a76bd !important;
      }
    }
    &.even {
      .row-cell {
        background-color: white !important;
        &.inactive-cell {
          background-color: #eee !important;
        }
      }
    }

    .row-cell {
      padding: 7px !important;
      min-height: unset !important;
      p {
        margin: 5px 0px !important;
      }

      h1 {
        font-size: 16px !important;
      }
      h2 {
        font-size: 14px !important;
      }
    }
  }
}

// quill style
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.radar-version-page.flash {
  .calendar {
    .row-cell.header-cell {
      text-align: center;
      h1 {
        font-size: 14px !important;
      }
    }
    &.radar_table_header_purple {
      .row-cell.header-cell {
        background-color: #a64c8cff !important;
      }
    }
    &.radar_table_header_darkpurple {
      .row-cell.header-cell {
        background-color: #5f488d !important;
      }
    }
    &.radar_table_header_blue {
      .row-cell.header-cell {
        background-color: #1a76bdff !important;
      }
    }
    &.radar_table_header_darkblue {
      .row-cell.header-cell {
        background-color: #04488f !important;
      }
    }
    &.radar_table_header_red {
      .row-cell.header-cell {
        background-color: #bf0000ff !important;
      }
    }

    .body-cell {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    .row-cell-with-logo {
      display: flex !important;
      flex-direction: row-reverse;
      justify-content: flex-end;
      //align-items: center;
      img {
        width: 40px !important;
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
  hr.section-separator {
    border: 2px solid rgba(55, 55, 55, 0.213);
    width: 60%;
    margin-left: 20%;
    margin-top: 40px;
    margin-bottom: 40px;

    &.radar_separator_purple {
      border-color: #a64c8cff !important;
    }
    &.radar_separator_darkpurple {
      border-color: #5f488d !important;
    }
    &.radar_separator_blue {
      border-color: #1a76bdff !important;
    }
    &.radar_separator_darkblue {
      border-color: #04488f !important;
    }
    &.radar_separator_red {
      border-color: #bf0000ff !important;
    }
    &.radar_separator_dark {
      border-color: rgb(55, 55, 55) !important;
    }
    &.radar_separator_transparent {
      border-color: rgba(64, 64, 64, 0) !important;
    }
  }
}

.accept{
  color:#8db854;
  font-weight:bold;
}

.reject{
  color:#ed6b75;
  font-weight:bold;
}
.token-url {
  text-align: center !important;
  a {
  color: #1a76bdff;
  mat-icon {
    scale: 0.8;
  }
  }
}